import Header from "components/_default/header/Header.js";
import Footer from "components/_default/footer/Footer.js";
import Select from "components/_default/select/Select.js";
import CheckBox from "components/_default/checkbox/Checkbox.js";
import RadioButton from "components/_default/radiobutton/Radiobutton.js";
import CheckIn from "components/registry-page/Registry.js";
// import About from "components/about-page/About.js";
import Basket from "components/basket/Basket.js";
import Product from "components/product-cart/Product.js";
import OrderDelivery from "components/order-checkout/OrderDelivery.js";
import Cabinet from "components/cabinet-page/Cabinet.js";
import Catalog from "components/catalog-page/Catalog.js";

document.addEventListener("DOMContentLoaded", function() {

	initSelects();

	initCheckboxes();

	initRadio();

	initHeader();

	initFooter();

	// new About();

	const	checkInElement = document.querySelector(".js-check-in");
	const	deliveryFormContainer = document.querySelector(".js-delivery-container");
	const	basketForm = document.querySelector(".js-basket-form");
	const	productCardInfo = document.querySelector(".js-product-info");
	const	privateCabinetContainer = document.querySelector(".js-cabinet");
	const	catalogContainer = document.querySelector(".js-catalog");

	if (catalogContainer) {
		initCatalog();
	}

	if (privateCabinetContainer) {
		new Cabinet(privateCabinetContainer);
	}

	if (productCardInfo) {
		new Product(productCardInfo);
	}


	if (basketForm) {
		new Basket(basketForm);
	}
	
	if (deliveryFormContainer) {
		new OrderDelivery(deliveryFormContainer);
	}

	if (checkInElement) {
		new CheckIn(checkInElement);
	}

	function initSelects() {
		const selectsElements = document.querySelectorAll(".js-select-wrapper");

		for (let i = 0, len = selectsElements.length; i < len; i++) {
			new Select(selectsElements[i]);
		}
	}

	function initCheckboxes() {
		const checkboxElemenets = document.querySelectorAll(".js-checkbox");

		for (let i = 0, len = checkboxElemenets.length; i < len; i++) {
			new CheckBox(checkboxElemenets[i]);
		}
	}

	function initRadio() {
		const radiobtns = document.querySelectorAll(".js-radiobtn");

		for (let i = 0, len = radiobtns.length; i < len; i++) {
			new RadioButton(radiobtns[i]);
		}
	}

	function initHeader() {
		const headerBlock = document.getElementById("header");

		new Header(headerBlock);
	}

	function initFooter() {
		const footerBlock = document.getElementById("footer");

		new Footer(footerBlock);
	}

	function initCatalog() {
		new Catalog(catalogContainer);
	}

});