import InputValidator from "Modules/InputValidator.js";

export default function Product(productInfoBlock) {
  const deliveryTitle = productInfoBlock.querySelector(".js-delivery-payment");
  const shopsTitle = productInfoBlock.querySelector(".js-shops");
  const deliveryInfo = productInfoBlock.querySelector(".js-delivery-payment__info");
  const shopsInfo = productInfoBlock.querySelector(".js-shops__info");
  const sizeGid = productInfoBlock.querySelector(".js-size-gid-link");
  const sizePopup = document.querySelector(".js-size-gid");
  const sizeGidClose = document.querySelector(".js-size-gid__close");
  const likeForm = productInfoBlock.querySelector(".js-like-form");
  const addInBasket = productInfoBlock.querySelector(".js-add-in-basket");

  if (deliveryTitle){
    deliveryTitle.addEventListener("click", function() {
      this.classList.add("active");
      deliveryInfo.classList.add("active");
      shopsInfo.classList.remove("active");
      shopsTitle.classList.remove("active");
    });
  }

  if (shopsTitle) {
    shopsTitle.addEventListener("click", function() {
      this.classList.add("active");
      shopsInfo.classList.add("active");
      deliveryInfo.classList.remove("active");
      deliveryTitle.classList.remove("active");
    })
  }

  if (sizeGid) {
    sizeGid.addEventListener("click", function(e) {
      e.preventDefault();
      sizePopup.classList.add("active");
    }); 
  }

  if (sizeGidClose) {
    sizeGidClose.addEventListener("click", closeGid);
  }
  
  function closeGid() {
    sizePopup.classList.remove("active");
  }

  document.addEventListener("click", function(e) {
    if (!e.target.classList.contains("js-gid-content") && 
      e.target.classList.contains("js-gid-wrapper")) {
      closeGid();
    }
  });

  window.addEventListener("resize", function() {
    if (screen.width < 1200) {
      deliveryTitle.classList.remove("hover");
      shopsTitle.classList.remove("hover");
    } else {
      if (!deliveryTitle.classList.contains("hover") && !shopsTitle.classList.contains("active")) {
        deliveryTitle.classList.add("hover");
        shopsTitle.classList.add("hover");
      }
    }
  })

  if (likeForm) {
    likeForm.addEventListener("submit", (e) => {
      e.preventDefault();
      const data = new FormData(likeForm);
      const url =likeForm.action;
  
      data.append("ajax", "Y");
      makeRequest(url, data, activateLikeButton);
    })
  }

  addInBasket.addEventListener("submit", function(e) {
    e.preventDefault();
    const data = new FormData(addInBasket);
    const url = addInBasket.action;

    makeRequest(url, data, function(){});

  })

  function makeRequest(url, data, successFunction) {
      fetch(url, {
        method: "POST",
        body: data
      }).then(function(response) {
        if (response.ok) {
          successFunction();
            return response.blob();
        }
        throw new Error("Network response was not ok.");
      });
  }

  function activateLikeButton() {
    const likeFormButton = likeForm.querySelector("button");
    const favoriteProductCount = document.querySelector(".js-favorite-produtcs__count");

    if (!likeFormButton.classList.contains("active")) {
      likeFormButton.classList.add("active");
      favoriteProductCount.innerText = parseInt(favoriteProductCount.innerText) + 1;
    } else {
      likeFormButton.classList.remove("active");
      favoriteProductCount.innerText = parseInt(favoriteProductCount.innerText) - 1;
    }

  }
}
