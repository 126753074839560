import InputValidator from "Modules/InputValidator.js";

export default function(privateCabinet) {
	const	contactInfoForm = privateCabinet.querySelector(".js-contacts");
	const	changePasswordForm = privateCabinet.querySelector(".js-password-form");
	const	adresForm = privateCabinet.querySelector(".js-delivery-adres");
	const	contactFields = contactInfoForm.querySelectorAll(".js-form-input");
	const	adresFields = adresForm.querySelectorAll(".js-form-input");
	const	removeFavoriteProductButton = privateCabinet.querySelectorAll(".js-remove_favorite-product");
    const   orderShowMoreBtn = privateCabinet.querySelectorAll(".js-show-order-popup");
    const   orderPopup = privateCabinet.querySelector(".js-order-popup");
    const   closePopupBtn = privateCabinet.querySelector(".js-close-popup");

	contactInfoForm.setAttribute("novalidate", true);
	changePasswordForm.setAttribute("novalidate", true);
	adresForm.setAttribute("novalidate", true);

	contactInfoForm.addEventListener("submit", function(e) {
		e.preventDefault();

        const isValid = validateForm(contactFields);
        const url = contactInfoForm.getAttribute("action")
        if (isValid) {
            const data = new FormData(contactInfoForm);
            data.append("ajax", "Y");
            makeRequest(url, data);
        }
        const contactFormButton = contactInfoForm.querySelector(".js-save-form");
        disableFormButton(contactFormButton);
	})

    adresForm.addEventListener("submit", function(e) {
        e.preventDefault();

        const isValid = validateForm(adresFields);
        const url = adresForm.getAttribute("action")
        if (isValid) {
            const data = new FormData(adresForm);
            data.append("ajax", "Y");
            makeRequest(url, data);
        }
        const contactFormButton = adresForm.querySelector(".js-save-form");
        disableFormButton(contactFormButton);
    })

    for (let i = 0, len = adresFields.length; i < len; i++) {
        adresFields[i].addEventListener("change", function() {
            const contactFormButton = adresForm.querySelector(".js-save-form");
            activateFormButton(contactFormButton);
        })
    }

	for (let i = 0, len = contactFields.length; i < len; i++) {
		contactFields[i].addEventListener("change", function() {
			const contactFormButton = contactInfoForm.querySelector(".js-save-form");
			activateFormButton(contactFormButton);
		})
	}

	for (let i = 0, len = removeFavoriteProductButton.length; i < len; i++) {
		removeFavoriteProductButton[i].addEventListener("click", function() {
			const productCard = removeFavoriteProductButton[i].parentElement.parentElement;
            var headerBadge = document.querySelector(".js-favorite-produtcs__count");

            headerBadge.innerText = parseInt(headerBadge.innerText) - 1;

            if (parseInt(headerBadge.innerText) === 0) {
                headerBadge.style.display = "none";
            }

			removeFavoriteProduct(productCard);
		})
	}

	function activateFormButton(formButton) {
		formButton.classList.remove("disable");
		formButton.removeAttribute("disabled");
	}

	function disableFormButton(formButton) {
		formButton.classList.add("disable");
		formButton.setAttribute("disabled");
	}

	function removeFavoriteProduct(product) {
		product.remove();
	}

    for (let i = 0, len = orderShowMoreBtn.length; i < len; i++) {
        orderShowMoreBtn[i].addEventListener("click", function(e) {
            e.preventDefault();

            orderPopup.classList.add("active");
        })        
    }

    closePopupBtn.addEventListener("click", function() {
        orderPopup.classList.remove("active");
    })
    
}

function validateTextInputs(inputsValidators) {
    let isValid = true;

    if (Array.isArray(inputsValidators)) {
        for (let i = 0, len = inputsValidators.length; i < len; i++) {
            const isFormElementValid = inputsValidators[i].validate();
            if (!isFormElementValid) {
                isValid = false;
            }
        }
    } else {
        const isFormElementValid = inputsValidators.validate();
        if (!isFormElementValid) {
            isValid = false;
        }
    }

    return isValid;
}

function validateForm(formInputs) {
    let isValid = true;

    const inputEmailInvalidClass = "error";

    for (var i = 0, len = formInputs.length; i < len; i++) {
        
        const inputEmailValidator = new InputValidator({
            "domElement": formInputs[i],
            "requiredFlag": formInputs[i].getAttribute("required"),
            "errorClass": inputEmailInvalidClass
        });

        const isTextInputsValid = validateTextInputs(inputEmailValidator);

        if (!isTextInputsValid) {
            isValid = false;
        }

    }


    return isValid;
}

function makeRequest(url, data) {
    fetch(url, {
        method: "POST",
        body: data
    }).then(function(response) {
        if (response.ok) {
            return response.blob();
        }

        throw new Error("Network response was not ok.");
    }).then(function() {
        // handleResponse();
    });
}