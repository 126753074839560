export default function Header(pageHeader) {
  const  searchButton = pageHeader.querySelector(".js-search-button");
  const  searchfield = pageHeader.querySelector(".js-search-field");
  const  userButton = pageHeader.querySelector(".js-user-sign");
  const  loginPopup = pageHeader.querySelector(".js-login__popup");
  const  popupClose = pageHeader.querySelectorAll(".js-login_popup-close");
  const  basketPopupCloseButton = pageHeader.querySelector(".js-basket-close");
  const  basketPopupRemoveProduct = pageHeader.querySelectorAll(".js-basket-popup__remove-button");
  const  searchForm = pageHeader.querySelector(".js-search-form");
  searchForm.setAttribute("novalidate", true);

  searchButton.addEventListener("click", function() {
    searchfield.classList.toggle("active");
  })

  userButton.addEventListener("click", function() {
    loginPopup.classList.add("active");
  })

  if (basketPopupCloseButton){
    basketPopupCloseButton.addEventListener("click", function() {
      const pageBody = document.querySelector("body");
  
      pageBody.classList.remove("basket-menu-open");
    })
  }

  for (var i = 0, len = popupClose.length; i < len; i++) {
    popupClose[i].addEventListener("click", function() {
      loginPopup.classList.remove("active");
    });
  }

  for (i = 0, len = basketPopupRemoveProduct.length; i < len; i++) {
    basketPopupRemoveProduct[i].addEventListener("click", function() {
      const product = closestParent(this, "js-basket-product");
      const productPrice = parseInt(product.querySelector(".js-product-price").innerText.replace(" ", ""));
      const totalSumBlock = pageHeader.querySelector(".js-total-price");
      const totalSumValue = parseInt(totalSumBlock.innerText.replace(" ", ""));
      const newSum = (totalSumValue - productPrice).toString();
      const len = newSum.length;
      const basketCountIcon = pageHeader.querySelector(".js-basket-products__count");

      totalSumBlock.innerText = newSum.slice(0, len - 3) + " " + newSum.slice(len - 3);
      product.remove();
      basketCountIcon.innerText = parseInt(basketCountIcon.innerText) - 1;

      if (parseInt(basketCountIcon.innerText) === 0) {
        basketCountIcon.style.display= "none";
      }
    })
  }

  searchForm.addEventListener("submit", (e) => {
    e.preventDefault();
    const url =  searchForm.action;
    const data = new new FormData(searchForm);

    makeRequest(url, data);

  })
}

function closestParent(element, className) {
  var elementContainer = element.parentElement;

  while (!elementContainer.classList.contains(className)) {
    elementContainer = elementContainer.parentElement;
  }

  return elementContainer;
}

 function makeRequest(url, data) {
      fetch(url, {
        method: "POST",
        body: data
      }).then(function(response) {
        if (response.ok) {
          return response.blob();
        }
        throw new Error("Network response was not ok.");
      });
  }