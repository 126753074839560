export default function basket(basketform) {
	const products = basketform.querySelectorAll(".js-product");

	for (let i = 0, len = products.length; i < len; i++) {
		const increment = products[i].querySelector(".js-count-increment"),
				decriment = products[i].querySelector(".js-count-decriment"),
				totalSum = basketform.querySelector(".js-total-sum"),
				remove = products[i].querySelector(".js-remove-product");

		increment.addEventListener("click", function() {
			const input = products[i].querySelector(".js-count-input"),
					unit = products[i].querySelector(".js-unit-price"),
					maxCount = input.getAttribute("max"),
					sum = products[i].querySelector(".js-product-sum");
			sum.innerText = productCount("incriment", input, unit.innerText, sum.innerText);

			if (parseInt(input.value) > parseInt(maxCount)) {
				input.value = parseInt(maxCount) - 1;
				sum.innerText = productCount("incriment", input, unit.innerText, sum.innerText);
			}

			totalSumUpdate(totalSum, products);

		});

		decriment.addEventListener("click", function() {
			const input = products[i].querySelector(".js-count-input"),
					unit = products[i].querySelector(".js-unit-price"),
					sum = products[i].querySelector(".js-product-sum");
			sum.innerText = productCount("decriment", input, unit.innerText, sum.innerText);

			if (sum.innerText === "0") {
				input.value = 1;
				removeProduct(products[i]);
			}

			totalSumUpdate(totalSum, products);
		});

		remove.addEventListener("click", function() {
			removeProduct(products[i]);

			totalSumUpdate(totalSum, basketform.querySelectorAll(".js-product"));
		})
	}

}

function productCount(type, input, unitPrice, sum) {
	if (type === "incriment") {
		input.value++;
	} else {
		input.value--;
	}

	sum = parseInt(unitPrice.replace(" ", "")) * parseInt(input.value);
	
	var len = sum.toString().length;

	sum = sum.toString().slice(0, len - 3) + " " + sum.toString().slice(len - 3);


	return sum;
}

function totalSumUpdate(totalSum, products) {
	var sum = 0;

	totalSum.innerHTML = "0";

	for (let i = 0, len = products.length; i < len; i++) {
		let productSum = parseInt(products[i].querySelector(".js-product-sum").innerHTML.replace(" ", ""));

		sum += productSum;
	}
	
	var len = sum.toString().length;

	sum = sum.toString().slice(0, len - 3) + " " + sum.toString().slice(len - 3);

	totalSum.innerHTML = sum;
}

function removeProduct(product) {
	product.parentNode.removeChild(product);
}