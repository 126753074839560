import elementClosest from "element-closest"; 
import InputValidator from "Modules/InputValidator.js";

export default function Catalog(catalogContainer) {
  const modalClose = document.querySelector(".js-close__modal");
  const productCards = catalogContainer.querySelectorAll(".js-product");
  const filterSelect = catalogContainer.querySelectorAll(".js-select");

  const checkBoxes = catalogContainer.querySelectorAll(".js-checkbox__input");
  const colorFilterLabel = catalogContainer.querySelector(".js-color-filter");
  const colorFilterList = catalogContainer.querySelector(".js-color__select-list");
  const colorListRadio = colorFilterList.querySelectorAll(".js-radiobtn");
  const addInBasket = document.querySelector(".js-modal-window").querySelector(".js-product-info-form");


  elementClosest(window);

  for (let i = 0, len = filterSelect.length; i < len; i++) {
    filterSelect[i].addEventListener("change", () => {
      const data = {filter: filterSelect[i].name, filterValue: filterSelect[i].value};
      
      takeNewProducts(data);
    })
  }

  for (let i = 0, len = checkBoxes.length; i < len; i++) {
    checkBoxes[i].addEventListener("change", () => {
      const data = {filter: checkBoxes[i].name, filterValue: checkBoxes[i].value, status: (checkBoxes[i].checked ? "add":"remove")}; 
      
      takeNewProducts(data);
    })
  }

  modalClose.addEventListener("click", () => {
    const modalWindow = document.getElementById("myModal");

    modalWindow.click();
  });

  function findClosestParent(element, className) {
    var findParent = element.parentElement;

    while (!findParent.classList.contains(className)) {
      findParent = findClosestParent(findParent, className);
    }

    return findParent;
  }

  initCardActions(productCards);  

  function takeNewProducts(data) {
    const url = "data/catalog-filter-responce.json";

     fetch(url, {
        method: "GET",
        data: data,
      }).then(function(response) {
        if (response.ok) {
          response.json().then((data) => {outputProducts(data)});
          // return response.blob();
        }
        throw new Error("Network response was not ok.");
      });
  }

  function outputProducts(result) {
    const outPutBlock = catalogContainer.querySelector(".js-products-list");

    for (let i = outPutBlock.children.length - 1; i >= 0; i--) {
      outPutBlock.children[i].remove();
    }

    outPutBlock.innerHTML = result.html;

    let productCards = catalogContainer.querySelectorAll(".js-product");

    initCardActions(productCards);
  }

  colorFilterLabel.addEventListener("click", () => {
    colorFilterList.classList.toggle("active");
    colorFilterLabel.querySelector(".color-filter__block").classList.toggle("open");
  })

  for (var i = 0, len = colorListRadio.length; i < len; i++) {
    colorListRadio[i].addEventListener("click", (e) => {
      
      const radioLabel = e.target.innerText;
      const colorSelect = colorFilterLabel.querySelector(".js-filter_color__label");
      const selectColorPreview = colorSelect.querySelector("span");
      const selectColorText = colorSelect.querySelectorAll("span")[1];
      const chooseColor = e.target.querySelector("span");

      colorFilterList.classList.remove("active");
      colorFilterLabel.querySelector(".color-filter__block").classList.remove("open");

      if (selectColorPreview) {
        selectColorPreview.style.backgroundColor = chooseColor.style.backgroundColor;
        selectColorText.innerText = radioLabel;
      }

      let colorInput = e.target.closest(".js-radiobtn").querySelector("input");
      let data = {"color": colorInput.getAttribute("data-value")}

      takeNewProducts(data);
    })
  }

  document.addEventListener("click", (e) => {

    if (e.target.closest(".js-color-filter") !== colorFilterLabel && 
      e.target.closest(".js-color__select-list") !== colorFilterList) {
      colorFilterList.classList.remove("active");
      colorFilterLabel.querySelector(".color-filter__block").classList.remove("open");
    }

  })



  addInBasket.addEventListener("submit", (e) => {
    e.preventDefault();

    const data = new FormData(addInBasket);
    const url = addInBasket.action;

    makeRequest(url, data, function(){}, null);
  })
}

function initCardActions(productCards) {
  for (let i = 0, len = productCards.length; i < len; i++) {
    const likeForm = productCards[i].querySelector(".js-like-form");

    likeForm.addEventListener("submit", (e) => {
      e.preventDefault();
      const data = new FormData(likeForm);
        const url = likeForm.action;

        data.append("ajax", "Y");
        makeRequest(url, data, activateLikeButton, likeForm);
    })
  }
}

function makeRequest(url, data, successFunction, form) {
  fetch(url, {
    method: "POST",
    body: data

    
  }).then(function(response) {
    if (response.ok) {
      successFunction(form);
      return response.blob();
    }
    throw new Error("Network response was not ok.");
  });
}

function activateLikeButton(form) {
  const likeFormButton = form.querySelector("button");
  const favoriteProductCount = document.querySelector(".js-favorite-produtcs__count");

  if (!likeFormButton.classList.contains("active")) {
    likeFormButton.classList.add("active");
    favoriteProductCount.innerText = parseInt(favoriteProductCount.innerText) + 1;
  }
}