import InputValidator from "Modules/InputValidator.js";

export default function CheckIn(container) {
    const form = container.querySelector(".js-check-in__form");
    form.setAttribute("novalidate", true);
    const url = form.action;

    const inputEmailElements = container.querySelectorAll(".js-form-input");

    form.addEventListener("submit", function(e) {
        e.preventDefault();

        const isValid = validateForm();
        if (isValid) {
            const data = new FormData(form);
            data.append("ajax", "Y");
            makeRequest(url, data);
        }
    });

    function makeRequest(url, data) {
        fetch(url, {
            method: "POST",
            body: data
        }).then(function(response) {
            if (response.ok) {
                return response.blob();
            }

            throw new Error("Network response was not ok.");
        }).then(function() {
            // handleResponse();
        });
    }

    // function handleResponse() {
    //     container.classList.add(containerActiveClass);
    // }

    function validateTextInputs(inputsValidators) {
        let isValid = true;

        if (Array.isArray(inputsValidators)) {
            for (let i = 0, len = inputsValidators.length; i < len; i++) {
                const isFormElementValid = inputsValidators[i].validate();
                if (!isFormElementValid) {
                    isValid = false;
                }
            }
        } else {
            const isFormElementValid = inputsValidators.validate();
            if (!isFormElementValid) {
                isValid = false;
            }
        }

        return isValid;
    }


    function validateForm() {
        let isValid = true;

        const inputEmailInvalidClass = "error";

        for (var i = 0, len = inputEmailElements.length; i < len; i++) {
            
            const inputEmailValidator = new InputValidator({
                "domElement": inputEmailElements[i],
                "requiredFlag": inputEmailElements[i].getAttribute("required"),
                "errorClass": inputEmailInvalidClass
            });

            const isTextInputsValid = validateTextInputs(inputEmailValidator);

            if (!isTextInputsValid) {
                isValid = false;
            }

        }


        return isValid;
    }
}