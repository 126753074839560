import InputValidator from "Modules/InputValidator.js";

export default function Ordercontainer(container) {
    const   useBonus = container.querySelector(".js-confirm-bonus");
    const   form = container.querySelector(".js-delivery-form");
    const   url = form.action;
    form.setAttribute("novalidate", true);

    const inputElements = form.querySelectorAll(".js-form-input");

    form.addEventListener("submit", function(e) {
        e.preventDefault();
        const isValid = validateForm();
        if (isValid) {
           const data = new FormData(form);
           
           data.append("ajax", "Y");
           makeRequest(url, data);
        }
    });

    useBonus.addEventListener("click", bonusCodeCheck);

    function bonusCodeCheck() {
        const bonusCode = container.querySelector(".js-bonus-code"),
            data = { bonusCode: bonusCode.value };

        checkCode(url, data);
    }

    function makeRequest(url, data) {
        fetch(url, {
            method: "POST",
            body: data
        }).then(function(response) {
            if (response.ok) {
                return response.blob();
            }
            throw new Error("Network response was not ok.");
        });
    }

    function checkCode(url, data) {
        fetch(url, {
            method: "POST",
            body: data
        }).then(function(response) {
            if (response.ok) {
                return response.blob();
            }
            throw new Error("Network response was not ok.");
        }).then(function() {
            bonusCodeSuccess();
        });
    }

    function bonusCodeSuccess() {
        const successMessage = container.querySelector(".js-success-msg"),
            useBonus = container.querySelector(".js-confirm-bonus"),
            bonusCode = container.querySelector(".js-bonus-code-wrap");

        successMessage.classList.add("success");
        useBonus.classList.add("success");
        bonusCode.classList.add("success");
    }

    function validateTextInputs(inputsValidators) {
        let isValid = true;

        if (Array.isArray(inputsValidators)) {
            for (let i = 0, len = inputsValidators.length; i < len; i++) {
                const isFormElementValid = inputsValidators[i].validate();
                if (!isFormElementValid) {
                    isValid = false;
                }
            }
        } else {
            const isFormElementValid = inputsValidators.validate();
            if (!isFormElementValid) {
                isValid = false;
            }
        }

        return isValid;
    }

    function validateForm() {
        let isValid = true;

        for (var i = 0, len = inputElements.length; i < len; i++) {
            const inputEmailInvalidClass = inputElements[i].getAttribute("data-invalid-class");
            const inputEmailValidator = new InputValidator({
                "domElement": inputElements[i],
                "requiredFlag": inputElements[i].getAttribute("required"),
                "errorClass": inputEmailInvalidClass
            });

            const isTextInputsValid = validateTextInputs(inputEmailValidator);

            if (!isTextInputsValid) {
                isValid = false;
            }
        }

        const subscribeInput = form.querySelector(".js-checkbox__input");
        const inputFieldValidator = new InputValidator({
                "domElement": subscribeInput,
                "requiredFlag": subscribeInput.getAttribute("required"),
                "errorClass": "error"
            });

        const isTextInputsValid = validateTextInputs(inputFieldValidator);

        if (!isTextInputsValid) {
            isValid = false;
        }

        return isValid;
    }

}