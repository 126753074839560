import InputValidator from "Modules/InputValidator.js";

export default function Footer (footerBlock) {
	const subscribeForm = footerBlock.querySelector(".js-subscribe-form");
    const subscribeFormPopup = subscribeForm.querySelector(".js-subscribe-success-popup");
    const subscribeFormPopupCloseBtn = subscribeFormPopup.querySelector(".js-subscribe-popup-close-btn");
	const url = subscribeForm.action;
	const inputEmailElements = subscribeForm.querySelectorAll("input");

	subscribeForm.setAttribute("novalidate", true);

	subscribeForm.addEventListener("submit", function (e) {
		e.preventDefault();

		const isValid = validateForm();
		if (isValid) {
			const data =  new FormData(subscribeForm);
			data.append("ajax", "Y");
			makeRequest(url, data);
		}
	});

    subscribeFormPopupCloseBtn.addEventListener("click", () => {
        subscribeFormPopup.classList.remove("subscribe__success-popup_open");
    })

	function makeRequest(url, data) {
        fetch(url, {
            method: "POST",
            body: data
        }).then(function(response) {
            if (response.ok) {
                return response.blob();               
            }
            successPopupOpen();
            throw new Error("Network response was not ok.");
        }).then(function() {
            successPopupOpen();
        });
    }

    function validateTextInputs(inputsValidators) {
        let isValid = true;

        if (Array.isArray(inputsValidators)) {
            for (let i = 0, len = inputsValidators.length; i < len; i++) {
                const isFormElementValid = inputsValidators[i].validate();
                if (!isFormElementValid) {
                    isValid = false;
                }
            }
        } else {
            const isFormElementValid = inputsValidators.validate();
            if (!isFormElementValid) {
                isValid = false;
            }
        }

        return isValid;
    }

    function validateForm() {
        let isValid = true;

        const inputEmailInvalidClass = "error";

        for (var i = 0, len = inputEmailElements.length; i < len; i++) {
            
            const inputEmailValidator = new InputValidator({
                "domElement": inputEmailElements[i],
                "requiredFlag": true,
                "errorClass": inputEmailInvalidClass
            });

            const isTextInputsValid = validateTextInputs(inputEmailValidator);

            if (!isTextInputsValid) {
                isValid = false;
            }

        }

        return isValid;
    }

    function successPopupOpen() {
        subscribeFormPopup.classList.add("subscribe__success-popup_open");
    }
}